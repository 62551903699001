import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import type { Cart, LineItem, PricedProduct, PricedVariant } from 'storefront-client';

// TODO: Detect user language
const locale = 'en-US';

export interface FormatPriceOptions extends Intl.NumberFormatOptions {
  quantity?: number;
}

export function formatPrice(amount: number | null, options?: FormatPriceOptions) {
  const defaultOptions = {
    currency: 'usd',
    quantity: 1,
    minimumFractionDigits: 2,
  };
  const { quantity, ...rest } = merge({}, defaultOptions, options);

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    ...rest,
  }).format(((amount || 0) / 100) * quantity);
}

export function sortProductVariantsByPrice(product: PricedProduct, currencyCode: string) {
  if (!product.variants) return [];
  return product.variants.sort((a, b) => getVariantPrice(a, currencyCode) - getVariantPrice(b, currencyCode));
}

export function getVariantPrice(variant: PricedVariant, currencyCode: string) {
  const { calculated, original } = getVariantPrices(variant, currencyCode);
  return isNumber(calculated) ? calculated : original;
}

export function getVariantPrices(variant: PricedVariant & { current_price?: number }, currencyCode = 'usd') {
  if (!variant || !variant.prices) return { original: 0, calculated: 0 };
  const price = variant.prices
    .filter((price) => price.currency_code === currencyCode)
    .sort((a, b) => a.amount - b.amount)[0];

  return {
    ...price,
    original: variant.current_price || price?.amount || 0,
    calculated: variant.calculated_price,
  };
}

export function variantSaleEndDate(variant: PricedVariant, currencyCode: string) {
  const variantPrices = getVariantPrices(variant, currencyCode);
  if ('price_list' in variantPrices && !!variantPrices.price_list?.ends_at)
    return new Date(variantPrices.price_list.ends_at);

  return null;
}

export function getCheapestProductVariant(product: PricedProduct, currencyCode: string) {
  return sortProductVariantsByPrice(product, currencyCode)[0];
}

export function getMostExpensiveProductVariant(product: PricedProduct, currencyCode: string) {
  const variants = sortProductVariantsByPrice(product, currencyCode);
  return variants[variants.length - 1];
}

export function getMinimumProductPriceValue(product: PricedProduct, currencyCode: string) {
  return getVariantPrice(getCheapestProductVariant(product, currencyCode), currencyCode);
}

export function formatLineItemPrice(lineItem: LineItem, regionCurrency: string) {
  return formatPrice(lineItem.unit_price, {
    currency: regionCurrency,
    quantity: lineItem.quantity,
  });
}

export function formatCartSubtotal(cart: Cart) {
  return formatPrice(cart.subtotal || 0, {
    currency: cart.region?.currency_code,
  });
}
